const orderDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/order/order-detail.vue")), "order");
const orderSubmit = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/order/order-submit.vue")), "org");
const orderList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/order/order-list.vue")), "order");
const orderMiddle = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/order/order-middle.vue")), "order");
const payRedirectUrl = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/order/redirectUrl.vue")), "order");
const orderEvaluate = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/order/order-evaluate.vue")), "order");
const appointCheck = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/order/appoint-check.vue")), "order");


export default
[
    {
        path: "/order/detail/:id",
        name: "orderDetail",
        component: orderDetail,
        meta:
        {
            title: "体检订单详情"
        }
    },
    {
        path: "/order/submit",
        name: "submit-order",
        component: orderSubmit,
        meta:
        {
            title: "体检提交订单"
        }
    },
    {
        path: "/order/middle",
        name: "orderMiddle",
        component: orderMiddle,
        meta:
        {
            title: "预约成功"
        }
    },
    {
        path: "/order/list",
        name: "order-list",
        component: orderList,
        meta:
        {
            title: "我的订单"
        }
    },
    {
        path: "/order/pay/redirectUrl",
        name: "payRedirectUrl",
        component: payRedirectUrl
    },
    {
        path: "/order/evaluate/:id",
        name: "orderEvaluate",
        component: orderEvaluate,
        meta:
        {
            title: "我的订单"
        }
    },
    {
        path: "/order/appointCheck",
        name: "appointCheck",
        component: appointCheck,
        meta:
        {
            title: "预约详情"
        }
    }
    
];
