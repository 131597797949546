
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 钉钉服务
 * @class
 * @version 1.0.0
 */
export default class DingTalkService extends ServiceBase
{
    /*
     * 钉钉实例
     * @private
     * @return DingTalkService
     */
    private static _instance: DingTalkService;

    /**
     * 钉钉实例
     * @static
     * @property
     * @returns DingTalkService
     */
    public static get instance(): DingTalkService
    {
        if(!this._instance)
        {
            this._instance = new DingTalkService();
        }
        
        return this._instance;
    }

    /**
     * 钉钉授权登录
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async dingTalkLogin(data): Promise<IHttpResponse>
    {

        let response = await this.apis.dingTalkLogin({
            data: data
        });

        return response;
    }






}
