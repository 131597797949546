
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import pdfJS from "pdfjs-dist";

/**
 * PDF预览组件
 * @class
 * @version 1.0.0
 */
@Component
export default class PDFPreview extends Vue
{
    /**
     * PDF路径
     * @private
     * @returns string
     */
    @Prop({type: String, required: true})
    private url: string;

    /**
     * 自定义渲染页数
     * @private
     * @returns number
     */
    @Prop({default: 100})
    private renderPages: any;

    /**
     * 自定义减去的高度
     * @private
     * @returns number
     */
    @Prop({default: 0})
    private offsetHeight: any;

    /**
     * PDF实例
     * @private
     * @returns pdfJS.build
     */
    private doc: pdfJS.build = null;
    
    /**
     * 总页数
     * @private
     * @returns number
     */
    private docPages: any = 0;

    /**
     * 当前页数
     * @private
     * @returns number
     */
    private currentPage: any = 0;

    /**
     * 页面高度
     * @private
     * @returns number
     */
    private pageHeight: any = 0;
    
    /**
     * 渲染的列表集合
     * @private
     * @returns Array<any>
     */
    private renderList: Array<any> = [];

    /**
     * 监听URl变化
     * @private
     * @returns void
     */
    @Watch("url",{immediate: true})
    private onChangeUrl(): void
    {
        this.getPDFFile();
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        document.addEventListener("scroll", this.scroll.bind(this));

        // 组件销毁前移除事件
        this.$once("hook:beforeDestroy", () =>
        {
            window.removeEventListener("scroll", this.scroll.bind(this));
        });
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private mounted(): void
    {
       document.getElementById("view").setAttribute("content", "width=device-width,initial-scale=1.0");
    }

    /**
     * 组件销毁前
     * @private
     * @returns void
     */
    private beforeDestroy(): void
    {
       document.getElementById("view").setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0");
    }

    /**
     * 页面滚动事件
     * @private
     * @returns void
     */
    private scroll(): void
    {
        this.checkRender(document.documentElement);
    }

    /**
     * 获取PDF文件信息
     * @private
     * @returns void
     */
    private getPDFFile(): void
    {
        if (!this.url) return;
        this.currentPage = 0;
        pdfJS.getDocument({
            url:this.url,
            cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.288/cmaps/",//必须引入字体文件
            // cMapUrl: "./cmaps/",//必须引入字体文件
            cMapPacked: true,
        }).then(pdf =>
        {
            this.doc = pdf;
            this.docPages = pdf.numPages;
            this.$nextTick(() =>
            {
                this.docPages && this.scrollToPage(1);
            });
        });
    }

    /**
     * 跳转到指定页数
     * @private
     * @param {number} pageNo 跳转第几页
     * @returns void
     */
    private scrollToPage(pageNo: any): void
    {
        if (this.currentPage === pageNo) return;
        this.currentPage = pageNo;
        let list = [];
        for (let page = pageNo - this.renderPages; page <= pageNo + this.renderPages; page++)
        {
            list.push(page);
        }

        list = list.filter(page => page <= this.docPages && page >= 1);
        this.$nextTick(() =>
        {
            this.renderList = list;
            this.renderList.forEach(page =>
            {
                this.renderPage(page);
            });
        });
    }

    /**
     * 渲染指定页数数据
     * @private
     * @param {number} pageNo 需要渲染数据
     * @returns void
     */
    private renderPage (pageNo: any)
    {
        this.doc.getPage(pageNo).then(page =>
        {
            let container = this.$refs.container[pageNo - 1];
            if (!container) return;
            let canvas = container.querySelector("canvas");
            if (!canvas || canvas.__rendered) return;
            let ctx = canvas.getContext("2d");
            let dpr = window.devicePixelRatio || 1;
            let bsr = ctx.webkitBackingStorePixelRatio || ctx.mozBackingStorePixelRatio || ctx.msBackingStorePixelRatio || ctx.oBackingStorePixelRatio || ctx.backingStorePixelRatio || 1;
            let ratio = dpr / bsr;
            let rect = container.getBoundingClientRect();
            let viewport = page.getViewport(1);
            let width = rect.width;
            let height = width / viewport.width * viewport.height;
            canvas.style.width = `${width}px`;
            canvas.style.height = `${height}px`;
            this.pageHeight = height;
            canvas.height = height * ratio;
            canvas.width = width * ratio;
            ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
            page.render({
                canvasContext: ctx,
                viewport: page.getViewport(width / viewport.width)
            });
            
            canvas.__rendered = true;
        });
    }

    /**
     * 渲染指定页数数据
     * @private
     * @returns void
     */
    private checkRender (el: HTMLElement) {
        if (!this.pageHeight) return;
        let scrollTop = el.scrollTop;
        if (el === document.documentElement)
        {
            scrollTop = el.scrollTop || window.pageYOffset || document.body.scrollTop;
        }
        let page = Math.floor((scrollTop - this.offsetHeight) / this.pageHeight);
        page = Math.max(page, 1);
        page = Math.min(page, this.docPages);
        
        this.scrollToPage(page);
    }
    
}
