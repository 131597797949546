const orgList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/org/org-list.vue")), "org");

export default
[
    {
        path: "/org/list",
        name: "orgList",
        component: orgList,
        meta:
        {
            title: "机构列表"
        }
    }
];
