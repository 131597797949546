const goodsDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/goods/detail.vue")), "goods");
const goodsOrderSubmit = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/goods/order-submit.vue")), "goods");
const goodsOrderSucess = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/goods/sucess.vue")), "goods");
const goodsOrderDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/goods/order-detail.vue")), "goods");
const goodsOrderAddressSucess = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/goods/address-sucess.vue")), "goods");
const goodsOrderUpdateAddress = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/goods/updaet-address.vue")), "goods");

export default
[
    {
        path: "/goods/detail",
        name: "goodsDetail",
        component: goodsDetail,
        meta:
        {
            title: "实物商品详情"
        }
    },
    {
        path: "/goods/order/submit",
        name: "goods-order-submit",
        component: goodsOrderSubmit,
        meta:
        {
            title: "实物商品确认订单"
        }
    },
    {
        path: "/goods/order/sucess",
        name: "goods-order-sucess",
        component: goodsOrderSucess,
        meta:
        {
            title: "实物商品购买成功"
        }
    },
    {
        path: "/goods/order/detail",
        name: "goods-order-detail",
        component: goodsOrderDetail,
        meta:
        {
            title: "实物订单详情"
        }
    },
    {
        path: "/goods/order/address/sucess",
        name: "goods-order-address-sucess",
        component: goodsOrderAddressSucess,
        meta:
        {
            title: "收货地址添加成功"
        }
    },
    {
        path: "/goods/order/update-address",
        name: "goods-order-update-address",
        component: goodsOrderUpdateAddress,
        meta:
        {
            title: "实物商品选择地址"
        }
    }
   
];
