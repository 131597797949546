import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible";
import "./registerServiceWorker";
import vant from "vant";
import "vant/lib/index.css";
import filters from "src/filters";
// import fastClick from "fastclick";
import commonComponents from "src/components";
import Vconsole from "vconsole";
import config from 'src/config'
// 神策
// import sensors from "./sensors"

// import './permission'
Vue.use(commonComponents);
Vue.use(vant);

if(config.isDebug)
{
    new Vconsole();
}
Vue.config.productionTip = false;

Object.keys(filters).forEach(key =>
{
    Vue.filter(key, filters[key]);
});
Vue.config.ignoredElements = ['wx-open-launch-weapp']
// Vue.prototype.$sensors = sensors;

new Vue({
  router,
  store,
  render: (h: any) => h(App)
}).$mount("#app");
