
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 意见反馈
 * @class
 * @version 1.0.0
 */
export default class FeedbackService extends ServiceBase
{
    /**
     * 服务实例
     * @private
     * @return FeedbackService
     */
    private static _instance: FeedbackService;

    /**
     * 服务实例
     * @static
     * @property
     * @returns FeedbackService
     */
    public static get instance(): FeedbackService
    {
        if(!this._instance)
        {
            this._instance = new FeedbackService();
        }
        
        return this._instance;
    }

    /**
     * 获取意见反馈列表
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async getFeedbackList(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getFeedbackList({
            data: data
        });

        return response;
    }

    /**
     * 新增意见反馈列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async addFeedback(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.addFeedback({
            data: data,
            serializeType:"form-data"
        });

        return response;
    }

    /**
     * 获取意见反馈
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getFeedbackDetail(id: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getFeedbackDetail({
            data: {id},
        });
        return response;
    }

    /**
     * 更新意见反馈状态
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async updateFeedback(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.updateFeedback({
            data: data,
            loading: true
        });

        return response;
    }

    /**
     * 获取未读消息
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getUnReadMessage(): Promise<IHttpResponse>
    {
        let response = await this.apis.getUnReadMessage({
           
        });

        return response;
    }
}
