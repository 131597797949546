
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import { GaodeService, OrganizationService } from "src/services";

/**
 * 加项包
 * @class
 * @version 1.0.0
 */
export default class SupplierService extends ServiceBase
{

    /*
     * 服务实例
     * @private
     * @return SupplierService
     */
    private static _instance: SupplierService;

    /**
     * 加项包服务实例
     * @static
     * @property
     * @returns SupplierService
     */
    public static get instance(): SupplierService
    {
        if(!this._instance)
        {
            this._instance = new SupplierService();
        }
        
        return this._instance;
    }


    /**
     * 获取预约须知详情
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getApptNotice(id: any): Promise<IHttpResponse>
    {

        let response = await this.apis.getApptNotice({
            params: {orgId:id}
        });

        return response;
    }

    /**
     * 客户端阅读预约须知
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async readApptNotice(data: any): Promise<IHttpResponse>
    {

        let response = await this.apis.readApptNotice({data});

        return response;
    }

    /**
     * 重置预约须知
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async resetRead(): Promise<IHttpResponse>
    {

        let response = await this.apis.resetRead({});

        return response;
    }


}
