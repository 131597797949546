import dd from "dingtalk-jsapi";
import router from "src/router";

export default class DdFun
{
    /**
     * 私有构造方法，使类型成为静态类。
     * @private
     */
    private constructor()
    {
    }
    
    /**
     * 钉钉环境赋值title;
     * @static
     * @param  title 标题名称
     */
    public static setTitle(title: string): boolean
    {
        if (!window.navigator.userAgent.includes("DingTalk") || !dd || !title) return;
        dd.biz.navigation.setTitle({
            title: title
        });
    }

    /**
     * 返回键盘处理
     * @static
     * @route  {any} 路由
     * @param  {any} name: {backName: "返回的页面路由name", isClose: false} 
     * @returns boolean
     */
    public static setBack(name: any , data: any)
    {
        const u = navigator.userAgent
        const that = this
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (!window.navigator.userAgent.includes("DingTalk") || !dd || !isiOS) return;
        dd.ready(() => {
            dd.biz.navigation.setLeft({
                control : true,//是否控制点击事件，true 控制，false 不控制， 默认false
                onSuccess : () => {
                    if (data[name])
                    {
                        if (data[name].isClose)
                        {
                            dd.biz.navigation.close({
                                onSuccess : () => {
                                    console.log("关闭成功~")
                                }
                            });
                        }
                        else
                        {
                            router.replace({name: data[name].backName});
                        }
                    }
                    else
                    {
                        router.go(-1);
                    }
                }
            });
        });
    }

}
