
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 实物订单服务。
 * @class
 * @version 1.0.0
 */
export default class healthOrserService extends ServiceBase
{
    /*
     * 实物订单服务实例
     * @private
     * @return healthOrserService
     */
    private static _instance: healthOrserService;

    /**
     * 实物订单服务实例
     * @static
     * @property
     * @returns healthOrserService
     */
    public static get instance(): healthOrserService
    {
        if(!this._instance)
        {
            this._instance = new healthOrserService();
        }
        
        return this._instance;
    }


    /**
     * 获取健康险订单下单前数据
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getHealthInsDetail(id: string): Promise<IHttpResponse>
    {
        let response = await this.apis.getHealthInsDetail({
            params:
            {
                id: id
            }
        });

        return response;
    }

   
}
