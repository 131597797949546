const aicustomized = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/aicustomized/index.vue")), "aicustomized");
const relatedReport = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/aicustomized/related-report.vue")), "aicustomized");
const replacementReport = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/aicustomized/replacementReport.vue")), "aicustomized");
const questionfill = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/aicustomized/question-fill.vue")), "aicustomized");
const historylist = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/aicustomized/history-list.vue")), "aicustomized");
const evaluationing = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/aicustomized/evaluationing.vue")), "aicustomized");
const cuspackage = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/aicustomized/cus-package.vue")), "aicustomized");




export default
[
    {
        path: "/aicustomized/index",
        name: "aicustomized",
        component: aicustomized,
        meta:
        {
            title: "AI智能体检管家"
        }
    },
    {
        path: "/aicustomized/related-report",
        name: "relatedReport",
        component: relatedReport,
        meta:
        {
            title: "健康评估问卷"
        }
    },
    {
        path: "/aicustomized/replacementReport",
        name: "replacementReport",
        component: replacementReport,
        meta:
        {
            title: "体检报告"
        }
    },
    {
        path: "/aicustomized/questionfill",
        name: "questionfill",
        component: questionfill,
        meta:
        {
            title: "健康评估问卷"
        }
    },
    {
        path: "/aicustomized/historylist",
        name: "historylist",
        component: historylist,
        meta:
        {
            title: "历史预测"
        }
    },
    {
        path: "/aicustomized/evaluationing",
        name: "evaluationing",
        component: evaluationing,
        meta:
        {
            title: "风险评估"
        }
    },
    {
        path: "/aicustomized/cuspackage",
        name: "cuspackage",
        component: cuspackage,
        meta:
        {
            title: "定制套餐"
        }
    }
];
