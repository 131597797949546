
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 收货地址服务
 * @class
 * @version 1.0.0
 */
export default class HealthProductService extends ServiceBase
{

    /*
     * 健康服务实例
     * @private
     * @return HealthProductService
     */
    private static _instance: HealthProductService;

    /**
     * 健康产品服务实例
     * @static
     * @property
     * @returns HealthProductService
     */
    public static get instance(): HealthProductService
    {
        if(!this._instance)
        {
            this._instance = new HealthProductService();
        }
        
        return this._instance;
    }

    /**
     * 获取健康产品详情
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getHealthProductDetail(id: string): Promise<IHttpResponse>
    {

        let response = await this.apis.getHealthProductDetail({
            data:
            {
                id: id
            }
        });

        return response;
    }

    /**
     * 获取健康产品列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getHealthProductList(data: any): Promise<IHttpResponse>
    {

        let response = await this.apis.getHealthProductList({
            data: data
        });

        return response;
    }

}
