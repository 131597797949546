const personalCenter = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/personal-center.vue")), "user");
const updatePwd = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/update-pwd.vue")), "user");
const updatePhone = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/update-phone.vue")), "user");
const retrievePwd = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/retrieve-pwd.vue")), "user");
const userSetting = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/user-setting.vue")), "user");
const feedbackList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/feedback/feedback.vue")), "user");
const feedbackDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/feedback/detail.vue")), "user");
const Privacy = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/privacy/index.vue")), "user");
const PrivacyDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/privacy/detail.vue")), "user");
const AddressList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/address/list.vue")), "user");
const AddressDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/address/detail.vue")), "user");
const PerfectUser = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/user/perfect-user.vue")), "user");
export default
[
    {
        path: "/personal-center",
        name: "personal-center",
        component: personalCenter,
        meta:
        {
            tabbarName: "personal-center",
            title:"我的"
        }
    },
    {
        path: "/update-pwd",
        name: "updatePwd",
        component: updatePwd,
        meta:
        {
            title:"修改登录密码"
        }
    },
    {
        path: "/retrieve-pwd",
        name: "retrievePwd",
        component: retrievePwd,
        meta:
        {
            title:"忘记密码"
        }
    },
    {
        path: "/update-phone",
        name: "updatePhone",
        component: updatePhone,
        meta:
        {
            title:"修改手机号"
        }
    },
    {
        path: "/user/setting",
        name: "userSetting",
        component: userSetting,
        meta:
        {
            title:"账号设置"
        }
    },
    {
        path: "/user/feedback/list",
        name: "feedbackList",
        component: feedbackList,
        meta:
        {
            title:"我的反馈"
        }
    },
    {
        path: "/user/feedback/detail",
        name: "feedbackDetail",
        component: feedbackDetail,meta:
        {
            title:"填写反馈"
        }
    },
   
    {
        path: "/user/privacy",
        name: "privacy",
        component: Privacy,
        meta:
        {
            title:"用户隐私协议"
        }
    },
    {
        path: "/user/privacy/detail",
        name: "privacy-detail",
        component: PrivacyDetail,
        meta:
        {
            title:"用户隐私协议"
        }
    },
    {
        path: "/address/list",
        name: "address-list",
        component: AddressList,
        meta:
        {
            title:"地址管理"
        }
    },
    {
        path: "/address/detail",
        name: "address-detail",
        component: AddressDetail,
        meta:
        {
            title:"地址详情"
        }
    },
    {
        path: "/perfect/user",
        name: "perfect-user",
        component: PerfectUser,
        meta:
        {
            title:"完善本人信息"
        }
    }
    
];
