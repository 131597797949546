const medicalDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/medical/detail.vue")), "medical");
const medicalOrderSubmit = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/medical/order-submit.vue")), "medical");
const medicalOrderSucess = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/medical/sucess.vue")), "medical");
const medicalOrderDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/medical/order-detail.vue")), "medical");
const medicalOrderAppointment = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/medical/appointment.vue")), "medical");
const medicalOrderAppointmentSucess = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/medical/appointment-sucess.vue")), "medical");
const MedicalOrgList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/medical/org-list.vue")), "medical");
const medicalBuy = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/medical/medical-buy.vue")), "medical");
const reservation = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/medical/greenway/reservation.vue")), "greenway");
const submitSuccess = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/medical/greenway/submit-success.vue")), "greenway");
const greenwayDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/healthProduct/medical/greenway/detail.vue")), "greenway");



export default
[
    {
        path: "/medical/detail",
        name: "medical",
        component: medicalDetail,
        meta:
        {
            title: "服务产品详情"
        }
    },
    {
        path: "/medical/medical-buy",
        name: "medical-buy",
        component: medicalBuy,
        meta:
        {
            title: "商品购买页"
        }
    },
    {
        path: "/medical/order/submit",
        name: "medical-order-submit",
        component: medicalOrderSubmit,
        meta:
        {
            title: "服务产品确认订单"
        }
    },
    {
        path: "/medical/order/sucess",
        name: "medical-order-sucess",
        component: medicalOrderSucess,
        meta:
        {
            title: "服务产品购买成功"
        }
    },
    {
        path: "/medical/order/detail",
        name: "medical-order-detail",
        component: medicalOrderDetail,
        meta:
        {
            title: "服务产品订单详情"
        }
    },
    {
        path: "/medical/org/list",
        name: "medical-org-list",
        component: MedicalOrgList,
        meta:
        {
            title: "机构列表"
        }
    },
    {
        path: "/medical/order/appointment",
        name: "medical-order-appointment",
        component: medicalOrderAppointment,
        meta:
        {
            title: "服务产品预约"
        }
    },
    {
        path: "/medical/order/appointment-sucess",
        name: "medical-order-appointment-sucess",
        component: medicalOrderAppointmentSucess,
        meta:
        {
            title: "服务产品预约成功"
        }
    },
    {
        path: "/greenway/reservation",
        name: "greenway-reservation",
        component: reservation,
        meta:
        {
            title: "预约信息"
        }
    },
    {
        path: "/greenway/submit-success",
        name: "greenway-submit-success",
        component: submitSuccess,
        meta:
        {
            title: "预约提交成功"
        }
    },
    {
        path: "/greenway/detail",
        name: "greenway-detail",
        component: greenwayDetail,
        meta:
        {
            title: "订单详情"
        }
    }
   
];
