import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import org from "./modules/org";
import packages from "./modules/package";
import order from "./modules/order";
import family from "./modules/family";
import report from "./modules/report";
import user from "./modules/user";
import notice from "./modules/notice";
import goods from "./modules/goods";
import medical from "./modules/medical";
import healthInsurance from "./modules/healthInsurance";
import broker from "./modules/broker";
import rights from "./modules/rights";
import contract from "./modules/contract";
import oppoPackage from "./modules/oppo/package";
import aicustomized from "./modules/aicustomized";
import aiAssistant from "./modules/ai/assistant";
import ticketing from "./modules/ticketing";
const login = () => import("src/views/login.vue");
const registerMain = () => import("src/views/register.vue");
const register = () => import("src/views/register/index.vue");
const verification = () => import("src/views/register/verification.vue");
const errPage = () => import("src/views/register/errPage.vue");
const home = () => import("src/views/home.vue");
const OHPSSO = () => import("src/views/OHPSSO.vue");
const HealthProductHome = () => import("src/views/healthProduct/home.vue");
const HealthProductList = () => import("src/views/healthProduct/list.vue");
const HealthProductArticle = () => import("src/views/healthProduct/article.vue");
const HealthProductArticleDetail = () => import("src/views/healthProduct/article-detail.vue");
const Pay = () => import("src/views/pay.vue");
const orderPay = () => import("src/views/orderPay.vue");
const loginPrivacy = () => import("src/views/user/privacy/login-privacy.vue");
const reportList = () => import("src/views/report/list.vue");
const rightsPrivacy = () => import("src/views/rights-privacy.vue");
const SSO = () => import("src/views/sso.vue");
const dingTalk = () => import("src/views/dingtalk.vue");
const oauth = () => import("src/views/oauth.vue");
const dsBridgeAuth = () => import("src/views/dsBridgeAuth.vue");
const bestdoBenefit = () => import("src/views/bestdoBenefit.vue");

// 神策
// import sensors from "../sensors"
Vue.use(VueRouter);

const routes: Array<RouteConfig> =
[
    {
        path: "/oauth",
        name: "oauth",
        component: oauth,
        meta:
        {
            title: "跳转中"
        }
    },
    {
        path: "/OHPSSO",
        name: "OHPSSO",
        component: OHPSSO,
        meta:
        {
            title: "跳转中"
        }
    },
    {
        path: "/dsBridgeAuth",
        name: "dsBridgeAuth",
        component: dsBridgeAuth,
        meta:
        {
            title: "跳转中"
        }
    },
    {
        path: "/register",
        name: "register",
        component: register,
        meta:
        {
            title: "个检登录页"
        }
    },
    {
        path: "/registerMain",
        name: "registerMain",
        component: registerMain,
        meta:
        {
            title: "账号注册"
        }
    },
    {
        path: "/SSO",
        name: "sso",
        component: SSO,
        meta:
        {
            title: "跳转中"
        }
    },
    {
        path: "/dingTalkAuth",
        name: "dingTalkAuth",
        component: dingTalk,
        meta:
        {
            title: "申通体检"
        }
    },
    {
        path: "/verification",
        name: "verification",
        component: verification,
        meta:
        {
            title: "输入验证码"
        }
    },
    {
        path: "/errPage",
        name: "errPage",
        component: errPage
    },
    {
        path: "/home",
        name: "home",
        component: home,
        meta:
        {
            title: "首页"
        }
    },
    {
        path: "/health/product/home",
        name: "health-product-home",
        component: HealthProductHome,
        meta:
        {
            title: "健康",
            tabbarName: "health-product-home"
        }
    },
    {
        path: "/health/product/list",
        name: "health-product-list",
        component: HealthProductList,
        meta:
        {
            tabbarName: "大家都在用"
        }
    },
    {
        path: "/health/product/article",
        name: "health-product-article",
        component: HealthProductArticle,
        meta:
        {
            tabbarName: "健康文章列表"
        }
    },
    {
        path: "/health/product/article/detail",
        name: "health-product-article-detail",
        component: HealthProductArticleDetail,
        meta:
        {
            tabbarName: "健康文章详情"
        }
    },
    {
        path: "/login-privacy",
        name: "login-privacy",
        component: loginPrivacy,
        meta:
        {
            tabbarName: "登录协议列表"
        }
    },
    {
        path: "/pay",
        name: "pay",
        component: Pay,
        meta:
        {
            tabbarName: "选择支付方式"
        }
    },
    {
        path: "/orderPay",
        name: "orderPay",
        component: orderPay,
        meta:
        {
            tabbarName: "订单详情"
        }
    },
    {
        path: "/bestdo-benefit",
        name: "bestdoBenefit",
        component: bestdoBenefit,
        meta:
        {
            title: "跳转中"
        }
    },
    {
        path: "/",
        redirect: "/login"
    },
    {
        path: "/login",
        name: "login",
        component: login,
        meta:
        {
            title: "体检登录页"
        }
    },
    ...org,
    ...packages,
    ...order,
    ...family,
    ...report,
    ...user,
    ...notice,
    ...goods,
    ...medical,
    ...broker,
    ...rights,
    ...oppoPackage,
    ...healthInsurance,
    ...aicustomized,
    ...contract,
    ...aiAssistant,
    ...ticketing,
];




let urlArr = location.pathname.split("/");
let appid = urlArr[1] || "aia";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL + `${appid}/`,
  routes
});

router.beforeEach((to, from, next) =>
{
    let orgName = "";
    let orgInfoStorge = localStorage.getItem("orgInfo");
    if(orgInfoStorge)
    {
        orgName =  JSON.parse(orgInfoStorge).orgName
    }
    if(to.meta.title || orgName)
    {
            document.title = to.meta.title || orgName;
    }
    
    let urlArr = location.pathname.split("/");
    let appid = urlArr[1] || "aia";
    let appidStorage = localStorage.getItem("appid");
    // 注册公共属性
    // sensors.registerPage({
    //     company_name: orgName || "",
    //     company_code: appidStorage || "",
    // });

    // 无默认首页的情况
    const whiteList = ['login', 'ai', 'ai-assistant', 'OHPSSO', 'oauth', 'dsBridgeAuth', 'sso', 'SSO'] 
    if(appidStorage && appid !== appidStorage)
    {
        localStorage.clear();
        if (whiteList.includes(to.name))
        {
            if (to.query)
            {
                next({name: to.name, query: to.query});
            }
            else
            {
                next({name: to.name});
            }
        }
        else
        {
            next({name:"login"});
        }
        localStorage.setItem("appid", appid);
        
    }else
    {
        localStorage.setItem("appid", appid);
    }

    next();
});

// router.afterEach((to,from) => {
// 	Vue.nextTick(() => {
// 		sensors.quick("autoTrackSinglePage");
// 	});
// });


export default router;
