
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 实物订单服务。
 * @class
 * @version 1.0.0
 */
export default class GoodsOrserService extends ServiceBase
{
    /*
     * 实物订单服务实例
     * @private
     * @return GoodsOrserService
     */
    private static _instance: GoodsOrserService;

    /**
     * 实物订单服务实例
     * @static
     * @property
     * @returns GoodsOrserService
     */
    public static get instance(): GoodsOrserService
    {
        if(!this._instance)
        {
            this._instance = new GoodsOrserService();
        }
        
        return this._instance;
    }

    /**
     * 客户端下实物订单
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async createGoodsOrder(models: any): Promise<IHttpResponse>
    {
        let response = await this.apis.createGoodsOrder({
            data: models
        });

        return response;
    }

    /**
     * 获取实物订单下单前数据
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getPreGoodsOrderInfo(id: string): Promise<IHttpResponse>
    {
        let response = await this.apis.getPreGoodsOrderInfo({
            params:
            {
                id: id
            }
        });

        return response;
    }

    /**
     * 获取实物订单详情
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getGoodsOrderDetail(orderId: string): Promise<IHttpResponse>
    {
        let response = await this.apis.getGoodsOrderDetail({
            data:
            {
                id: orderId
            }
        });

        return response;
    }


    /**
     * 取消实物订单
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async cancelGoodsOrder(orderId: string): Promise<IHttpResponse>
    {
        let response = await this.apis.cancelGoodsOrder({
            params:
            {
                id: orderId
            }
        });

        return response;
    }

    /**
     * 继续支付
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async proceedpayGoodsOrder(id: string): Promise<IHttpResponse>
    {
        let response = await this.apis.proceedpayGoodsOrder({
            data:
            {
                id: id
            }
        });

        return response;
    }

    /**
     * 实物订单确认收货
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async confirmGoodsOrder(id: string): Promise<IHttpResponse>
    {
        let response = await this.apis.confirmGoodsOrder({
            params:
            {
                id: id
            }
        });

        return response;
    }

    /**
     * 确认实物订单（体检套餐过来未选择收货地址）
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async confirmGoodsOrderInfo(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.confirmGoodsOrderInfo({
            data: data
        });

        return response;
    }

    /**
     * 修改收货地址
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async modifyGoodsOrderAddress(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.modifyGoodsOrderAddress({
            data: data
        });

        return response;
    }

    /**
     * 撤销申请退款
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async onRevokeGoods(id: any): Promise<IHttpResponse>
    {
        let response = await this.apis.onRevokeGoods({
            params: {id}
        });

        return response;
    }
   
}
