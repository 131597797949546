const notice = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/notice/notice.vue")), "notice");
const noticeDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/notice/notice-detail.vue")), "notice");

export default
[
    {
        path: "/notice",
        name: "notice",
        component: notice,
        meta:
        {
            title: "通知公告列表",
            tabbarName: "notice"
        }
    },
    {
        path: "/notice/detail",
        name: "notice-detail",
        component: noticeDetail,
        meta:
        {
            title: "通知公告详情",
            tabbarName: "notice"
        }
    }
];
