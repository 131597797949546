
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";

/**
 * 体检合同服务
 * @class
 * @version 1.0.0
 */
export default class ReimbursementService extends ServiceBase
{

    /*
     * 体检合同服务实例
     * @private
     * @return ReimbursementService
     */
    private static _instance: ReimbursementService;

    /**
     * 体检合同服务实例
     * @static
     * @property
     * @returns ReimbursementService
     */
    public static get instance(): ReimbursementService
    {
        if(!this._instance)
        {
            this._instance = new ReimbursementService();
        }
        
        return this._instance;
    }
    
    
    
    /**
     * 提交报销申请
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async submitReimbursement(data: any): Promise<IHttpResponse>
    {

        let response = await this.apis.submitReimbursement({ 
            data:data,
            serializeType:"form-data" 
        });

        return response;
    }

    /**
     * 撤销报销申请
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async revokeReimbursement(id: any): Promise<IHttpResponse>
    {

        let response = await this.apis.revokeReimbursement({params: {id: id}});

        return response;
    }

    /**
     * 查询报销数据
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async queryReimbursement(data: any): Promise<IHttpResponse>
    {

        let response = await this.apis.queryReimbursement({ data });

        return response;
    }


    
    
}
