
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";

/**
 * 企业隐私条款管理服务
 * @class
 * @version 1.0.0
 */
export default class PrivacyTermService extends ServiceBase
{

    /*
     * 企业隐私条款管理服务实例
     * @private
     * @return PrivacyTermService
     */
    private static _instance: PrivacyTermService;

    /**
     * 企业隐私条款管理服务实例
     * @static
     * @property
     * @returns PrivacyTermService
     */
    public static get instance(): PrivacyTermService
    {
        if(!this._instance)
        {
            this._instance = new PrivacyTermService();
        }
        
        return this._instance;
    }

    /**
     * 客户端校验最新版本的隐私条款
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async checkPrivacyTerm(): Promise<IHttpResponse>
    {
        let response = await this.apis.checkPrivacyTerm({});

        return response;
    }

    /**
     * 客户端获取最新版本的隐私条款
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getNewestPrivacyTerm(): Promise<IHttpResponse>
    {
        let response = await this.apis.getNewestPrivacyTerm({});

        return response;
    }

    /**
     * 客户端获取最新版本的隐私条款
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getPrivacyTerm(id: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getPrivacyTerm({data:{id}});

        return response;
    }

    /**
     * 客户端获取最新版本的隐私条款
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async agreePrivacyTerm(id: any): Promise<IHttpResponse>
    {
        let response = await this.apis.agreePrivacyTerm({data:{id}});

        return response;
    }
}
