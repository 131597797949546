import { register } from 'register-service-worker';

import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import { tools } from "src/utils";

/**
 * 登录服务。
 * @class
 * @version 1.0.0
 */
export default class UserService extends ServiceBase
{
    /**
     * 用户服务实例
     * @private
     * @return UserService
     */
    private static _instance: UserService;

    /**
     * 用户实例
     * @static
     * @property
     * @returns UserService
     */
    public static get instance(): UserService
    {
        if(!this._instance)
        {
            this._instance = new UserService();
        }
        
        return this._instance;
    }

    /**
     * 登录
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async login(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.login({
            data: data
        });

        return response;
    }

    /**
     * 业务员
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async uniteLogin(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.uniteLogin({
            data: data
        });

        return response;
    }

    /**
     * 客户端验证码登录
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async smsLogin(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.smsLogin({
            data: data
        });

        return response;
    }

    /**
     * 修改密码
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async forgetPassword(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.forgetPassword({
            data: data
        });

        return response;
    }

    /**
     * 根据公司code获取公司信息
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getOrgInfo(code: string): Promise<IHttpResponse>
    {
        let response = await this.apis.getOrgInfo({
            params: {code}
        });

        return response;
    }

    /**
     * 根据登入人获取关联企业
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getOrgList(): Promise<IHttpResponse>
    {
        let response = await this.apis.getOrgList({
            
        });

        return response;
    }

    /**
     * 根据登入人获取关联企业
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async changeToken(id?: any): Promise<IHttpResponse>
    {
        let response = await this.apis.changeToken({
            params:{id}
        });

        return response;
    }

    /**
     * 修改头像
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async uploadAvatar(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.uploadAvatar({
            data:data,
            serializeType:"form-data"
        });

        return response;
    }

    /**
     * 修改手机号(需要登入密码)
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async modifyPhone(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.modifyPhone({
            data:data
        });

        return response;
    }

    /**
     * 修改手机号(不需要登入密码)
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async modifyPhoneTwo(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.modifyPhoneTwo({
            data:data
        });

        return response;
    }

    /**
     * 发送验证码
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async smsSend(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.smsSend({
            data: data
        });

        return response;
    }

    /**
     * 微信授权
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async wechatOauth(url: any, isAllowAnonymous: boolean = false): Promise<IHttpResponse>
    {
        let response = await this.apis.wechatOauth({
            data:
            {
                url: url,
                isAllowAnonymous: isAllowAnonymous
            }
        });

        return response;
    }

    /**
     * 修改密码
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async updatePwd(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.updatePwd({
            data: data
        });

        return response;
    }

    /**
     * 公告详情
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getNotice(id: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getNotice({urlPath:{id}});

        return response;
    }

    /**
     * 公告详情
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async checkIsPerfect(): Promise<IHttpResponse>
    {
        let response = await this.apis.checkIsPerfect({});

        return response;
    }

    /**
     * 跳转个人版，获取Token的Key
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async jump2Personal(): Promise<IHttpResponse>
    {
        if (localStorage.getItem("appid") == "OPPO" || !tools.isWechatH5())
        {
            return { content: { data: null }, code: 200 };
        }
        else
        {
            let response = await this.apis.jump2Personal({});
            return response;   
        }
    }

    /**
     * 获取最新的未提交预约解读报告
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async queryNotSumbitAnalyzeReport(): Promise<IHttpResponse>
    {
        let response = await this.apis.queryNotSumbitAnalyzeReport({});

        return response;
    }

    /**
     * sso单点登入
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async SSOLogin(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.SSOLogin({
            data: data
        });

        return response;
    }

    /**
     * oppo单点登入
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async oppoLogin(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.oppoLogin({
            data: data
        });

        return response;
    }

    /**
     * vivo单点登入
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async vivoLogin(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.vivoLogin({
            data: data
        });

        return response;
    }

    /**
     * 注册
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async signUpClientAuth(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.signUpClientAuth({
            data: data
        });

        return response;
    }

    /**
     * 校验是否可以删除家属
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async checkDeleteFamily(id: any): Promise<IHttpResponse>
    {
        let response = await this.apis.checkDeleteFamily({params:{id}});

        return response;
    }

    /**
     * 删除家属
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async deleteFamily(id: any): Promise<IHttpResponse>
    {
        let response = await this.apis.deleteFamily({params:{id}});

        return response;
    }

    /**
     * 浙商银行登录
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async bestdoLogin(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.bestdoLogin({
            data: data
        });

        return response;
    }

    /**
     * 获取最新的未提交预约解读报告
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async url2base64(url: string): Promise<IHttpResponse>
    {
        let response = await this.apis.url2base64({params:{url}});

        return response;
    }

}
