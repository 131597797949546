const rightsList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/rights/list.vue")), "rights");
const rightsDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/rights/detail.vue")), "rights");
const rightsInformation = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/rights/information.vue")), "rights");
const rightsOrderDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/rights/order-detail.vue")), "rights");


export default
[
    {
        path: "/rights/list",
        name: "rights-list",
        component: rightsList,
        meta:
        {
            title: "权益中心"
        }
    },
    {
        path: "/rights/detail",
        name: "rights-detail",
        component: rightsDetail,
        meta:
        {
            title: "权益中心"
        }
    },
    {
        path: "/rights/information",
        name: "rights-information",
        component: rightsInformation,
        meta:
        {
            title: "卡片信息"
        }
    },
    {
        path: "/rights/order/detail",
        name: "rights-order-detail",
        component: rightsOrderDetail,
        meta:
        {
            title: "订单详情"
        }
    }
];
