
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 健康短文服务。
 * @class
 * @version 1.0.0
 */
export default class ArticleService extends ServiceBase
{
    /*
     * 健康短文服务实例
     * @private
     * @return ArticleService
     */
    private static _instance: ArticleService;

    /**
     * 健康短文服务实例
     * @static
     * @property
     * @returns ArticleService
     */
    public static get instance(): ArticleService
    {
        if(!this._instance)
        {
            this._instance = new ArticleService();
        }
        
        return this._instance;
    }

    /**
     * 获取文章列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getHealthArticleList(): Promise<IHttpResponse>
    {
        let response = await this.apis.getHealthArticleList({
            
        });

        return response;
    }

    /**
     * 获取文章详情
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getHealthArticleDetail(id: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getHealthArticleDetail({
            params:
            {
                id
            }
        });

        return response;
    }

    /**
     * 获取文章分页列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getHealthArticlePageList(data): Promise<IHttpResponse>
    {
        let response = await this.apis.getHealthArticlePageList({
            data
        });

        return response;
    }

}
