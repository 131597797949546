const collect = () => import("src/views/attachment-file/collect.vue");
const share = () => import("src/views/attachment-file/share.vue");
const sdk = () => import("src/views/attachment-file/sdk.vue");

export default
[
    {
        path: "/attachment/collect",
        name: "collect",
        component: collect
    },
    {
        path: "/attachment/share",
        name: "share",
        component: share
    },
    {
        path: "/attachment/sdk",
        name: "sdk",
        component: sdk
    },
];
