const memberReimbursementList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/memberReimbursement/memberReimbursement-list.vue")), "report");
const memberReimbursementSubmit = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/memberReimbursement/memberReimbursement-submit.vue")), "report");
const bankList = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/memberReimbursement/bank-list.vue")), "report");




export default
[
    {
        path: "/ticketing/list",
        name: "ticketingList",
        component: memberReimbursementList,
        meta:
        {
            tabbarName: "ticketingList",
            title: "上传记录"
        }
    },
    {
        path: "/ticketing/submit",
        name: "ticketingSubmit",
        component: memberReimbursementSubmit,
        meta:
        {
            tabbarName: "ticketingSubmit",
            title: "上传发票",
        }
    },
    {
        path: "/ticketing/banklist",
        name: "banklist",
        component: bankList,
        meta:
        {
            tabbarName: "bankList",
            title: "选择收款银行"
        }
    }
];