const packageDetail = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/package/package-detail.vue")), "package");
const optionalPackage = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/package/optional-package.vue")), "package");
const physicalPackage = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/package/physical-package-list.vue")), "package");
const inspection = (resolve: any) => (<any>require).ensure([], () => resolve(require("src/views/package/inspection.vue")), "package");


export default
[
    {
        path: "/package-detail",
        name: "package-detail",
        component: packageDetail,
        meta:
        {
            title: "体检套餐详情"
        }
    },
    {
        path: "/optional-package",
        name: "optional-package",
        component: optionalPackage,
        meta:
        {
            title: "体检自选加项"
        }
    },
    {
        path: "/physical-package",
        name: "physical-package",
        component: physicalPackage,
        meta:
        {
            title: "体检套餐选择"
        }
    },
    {
        path: "/physical-inspection",
        name: "physical-inspection",
        component: inspection,
        meta:
        {
            title: "检前须知"
        }
    }
];
