
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 订单 服务。
 * @class
 * @version 1.0.0
 */
export default class OrderService extends ServiceBase
{
    /*
     * 订单服务实例
     * @private
     * @return GaodeService
     */
    private static _instance: OrderService;

    /**
     * 订单服务实例
     * @static
     * @property
     * @returns OrderService
     */
    public static get instance(): OrderService
    {
        if(!this._instance)
        {
            this._instance = new OrderService();
        }
        
        return this._instance;
    }

    /**
     * 提交订单
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async submitOrder(models: any): Promise<IHttpResponse>
    {
        let response = await this.apis.submitOrder({
            data: models
        });

        return response;
    }

    /**
     * 获取订单详情
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getOrderDetail(orderId: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getOrderDetail({
            params:
            {
                id: orderId
            }
        });

        return response;
    }

    /**
     * 修改订单预约日期
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async modifiedOrderDate(orderId: any, orderedDate: string, physicalTimePeriod: string): Promise<IHttpResponse>
    {
        let response = await this.apis.modifiedOrderDate({
            data:
            {
                id: orderId,
                changeTime: orderedDate,
                physicalTimePeriod: physicalTimePeriod
            }
        });

        return response;
    }

    /**
     * 取消订单
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async cancelOrder(orderId: any): Promise<IHttpResponse>
    {
        let response = await this.apis.cancelOrder({
            params:
            {
                id: orderId
            }
        });

        return response;
    }

    /**
     * 继续支付
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async proceedPay(id: any): Promise<IHttpResponse>
    {
        let response = await this.apis.proceedPay({
            data:
            {
                id: id
            }
        });

        return response;
    }

    /**
     * 订单列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getOrderList(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getOrderList({
            data: data
        });

        return response;
    }

    /**
     * 订单数量
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getOrderQuantity(): Promise<IHttpResponse>
    {
        let response = await this.apis.getOrderQuantity({});
        return response;
    }
    /**
     * 客户端订单预信息（下单前的信息汇总）
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getPreOrderInfo(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getPreOrderInfo({
            data
        });
        return response;
    }

    /**
     * 体检预约验证
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async appointmentverification(): Promise<IHttpResponse>
    {
        let response = await this.apis.appointmentverification({});
        return response;
    }

    /**
     * 客户端获取预支付订单数据
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getPayPreByNo(orderNo: string): Promise<IHttpResponse>
    {
        let response = await this.apis.getPayPreByNo({params:{
            orderNo
        }});
        return response;
    }

    /**
     * 客户端获取预支付订单数据 -- 匿名
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getPayPreByNoAnonymous(orderNo: string): Promise<IHttpResponse>
    {
        let response = await this.apis.getPayPreByNoAnonymous({params:{
            orderNo
        }});
        return response;
    }

    /**
     * 客户端订单统一支付
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async toPay(data: any, paymentType: any, XWXOPENID: string = ""): Promise<IHttpResponse>
    {
        let headers = {
            "X-PAYMENTTYPE": paymentType
        }
        if(XWXOPENID)
        {
            headers["X-WXOPENID"] = XWXOPENID;
        }
        let response = await this.apis.toPay({
            data: data,
            headers: headers
        });
        return response;
    }

    /**
     * 客户端获取预支付订单数据
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getAllowAnonymou(sceneStr: string): Promise<IHttpResponse>
    {
        let response = await this.apis.getAllowAnonymou({
            params:{sceneStr}
        }
        );
        return response;
    }

    /**
     * 客户端订单统一支付
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async toPayAnonymous(data: any, paymentType: any): Promise<IHttpResponse>
    {
        let response = await this.apis.toPayAnonymous({
            data: data,
            headers:{
                "X-PAYMENTTYPE": paymentType,
                "X-WXOPENID": localStorage.getItem("openid") || "",
            }
        });
        return response;
    }

     /**
     * 是否有有效订单
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async checkHasEffOrder(id: any): Promise<IHttpResponse>
    {
         let response = await this.apis.checkHasEffOrder({
            params:{id}
         });
         return response;
    }
    /**
     * 查询是否存在套餐
     * @public
     * @returns {Promise<IHttpResponse>}
     */
    public async checkFamilyPackage(): Promise<IHttpResponse>{
        let response  = await this.apis.checkFamilyPackage({
            // data:data,

        })
        return response
    }

    /**
     * @description 获取评价订单详情
     * @function getEvaluateData
     * @returns {Promise<IHttpResponse>}
     * @public
     */
    public async getEvaluateData(id: any): Promise<IHttpResponse> {
        let response = await this.apis.getEvaluateData({ params: { id } });
        return response;
    }

    /**
    * @description 客户端提交评价前获取订单信息
    * @public
    * @returns {Promise<IHttpResponse>}
    */
    public async evaluateBeforeAdd(data: any): Promise<IHttpResponse> {
        let response = await this.apis.evaluateBeforeAdd({ data });
        return response;
    }

    /**
    * @description 客户端提交订单评价
    * @public
    * @returns {Promise<IHttpResponse>}
    */
    public async evaluateAdd(data: any): Promise<IHttpResponse> {
        let response = await this.apis.evaluateAdd({ data });
        return response;
    }

    /**
     * 客户端体检预约校验数据
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async appointCheck(params?: any): Promise<IHttpResponse>
    {
        let response = await this.apis.appointCheck({
            params
        });

        return response;
    }
}
