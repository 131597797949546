
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";

/**
 * 体检合同服务
 * @class
 * @version 1.0.0
 */
export default class ContractService extends ServiceBase
{

    /*
     * 体检合同服务实例
     * @private
     * @return ContractService
     */
    private static _instance: ContractService;

    /**
     * 体检合同服务实例
     * @static
     * @property
     * @returns ContractService
     */
    public static get instance(): ContractService
    {
        if(!this._instance)
        {
            this._instance = new ContractService();
        }
        
        return this._instance;
    }
    
    /**
     * 获取通知公告Banner列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getNoticeBannerList(): Promise<IHttpResponse>
    {

        let response = await this.apis.getNoticeBannerList({
        });

        return response;
    }
    
    /**
     * 获取通知公告跑马灯列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getNoticeMarqueeList(): Promise<IHttpResponse>
    {

        let response = await this.apis.getNoticeMarqueeList({
        });

        return response;
    }
    
    /**
     * 获取通知弹框跑马灯列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getNoticePopUpList(): Promise<IHttpResponse>
    {

        let response = await this.apis.getNoticePopUpList({
        });

        return response;
    }
    
    /**
     * 读取消息
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async noticeRead(data: any): Promise<IHttpResponse>
    {

        let response = await this.apis.noticeRead({ data });

        return response;
    }
    
    /**
     * 消息分页
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async noticePageList(data: any): Promise<IHttpResponse>
    {

        let response = await this.apis.noticePageList({ data });

        return response;
    }
    
    /**
     * 获取通知公告实体类
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getNotice(id: any): Promise<IHttpResponse>
    {

        let response = await this.apis.getNotice({ params: {id: id} });

        return response;
    }
    
    /**
     * 获取人员折扣余额
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getMemberAsset(empolyerMemberId: any): Promise<IHttpResponse>
    {

        let response = await this.apis.getMemberAsset({ params: {empolyerMemberId: empolyerMemberId} });

        return response;
    }


    /**
     * 是否可以修改婚姻状态
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async isUpdateMarriageStatus(): Promise<IHttpResponse>
    {
        let response = await this.apis.isUpdateMarriageStatus({});
        return response;
    }

    /**
     * 更新婚姻状态
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async updateMarriageStatus(status): Promise<IHttpResponse>
    {
        let response = await this.apis.updateMarriageStatus({params:{status}});
        return response;
    }

    /**
     * 是否展示检前须知
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async isShowPhysicalNotice(): Promise<IHttpResponse>
    {
        let response = await this.apis.isShowPhysicalNotice({});
        return response;
    }

    /**
     * 确认检前须知
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async confirmPhysicalNotice(): Promise<IHttpResponse>
    {
        let response = await this.apis.confirmPhysicalNotice({});
        return response;
    }
    
}
