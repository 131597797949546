
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { PackageService, FamilyService } from "src/services";
import EnumUtils from "src/utils/enum-utils";
import { RELATION } from "src/enums";

@Component
export default class FamilyList extends Vue
{
    /**
     * 是否显示
     * @private
     * @returns string
     */
    @Prop({type: Boolean, default: false})
    private value: boolean;

    /**
     * 是否显示本人
     * @private
     * @returns boolean
     */
    @Prop({type: Boolean, default: true})
    private isShowSelf: boolean;

    /**
     * 当前选中用户
     * @private
     * @returns string
     */
    @Prop({})
    private selectUserId: any;

    /**
     * 是否vivo项目
     * @private
     * @returns string
     */
    @Prop({type: Boolean, default:()=>(false)})
    private isVivo: boolean;

    /**
     * 是否传递参数
     * @private
     * @returns string
     */
     @Prop({type: Boolean, default:()=>(false)})
    private reportList: boolean;

    /**
     * 是否显示日期弹框 
     * @private
     * @returns boolean
     */
    private get showUserPopup(): boolean
    {
        return this.value;
    }

    /**
     * 返回弹框状态 
     * @private
     */
    private set showUserPopup(value: boolean)
    {
        this.$emit("input", value);
    }


    /**
     * 监听弹框变化
     * @private
     * @returns void
     */
    @Watch("selectUserId",{immediate: true})
    private onSelectUserIdChange(value: any): void
    {
        if(value)
        {
            this.currentIndex = this.familyList.findIndex(item=>item.employerMemberId==value);
        }
    }

    /**
     * 当前选中用户
     * @protected
     * @returns string
     */
    protected currentIndex: any = 0;

    /**
     * 家属列表
     * @protected
     * @returns Array<any>
     */
    protected familyList: Array<any> = [];

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    protected get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 获取与本人关系
     * @private
     * @returns string
     */
    protected getRelationship(value: any): string
    {
        return EnumUtils.getEntry(value, RELATION) && EnumUtils.getEntry(value, RELATION).description;
    }

    /**
     * 当前选中用户
     * @private
     * @returns void
     */
    protected onSelectUser(index: any): void
    {
        this.currentIndex = index;
    }

    /**
     * 添加用户
     * @protected
     * @returns string
     */
    protected addUser(): void
    {
        if(this.isVivo)
        {
            this.$router.push({name: "vivo-family-list"});
        }else
        {
            this.$router.push({name: "family-operation"});
        }
        
    }

    /**
     * 获取体检用户家属数据列表
     * @private
     * @returns void
     */
    protected async checkFamilyList(): Promise<void>
    {
        try
        {
            let result
            if(this.reportList) {
                let res =  await FamilyService.instance.getFamilyList(1);
                result = res.content

            } else {
                let res =  await FamilyService.instance.getFamilyList();
                result = res.content
            }

            if(result.data)
            {
                this.$emit("getUserList", result.data);
                this.familyList = JSON.parse(JSON.stringify(result.data));
                if(!this.isShowSelf)
                {
                    this.familyList.splice(0,1);
                    
                }
                
                if(this.selectUserId)
                {
                    this.currentIndex = this.familyList.findIndex(item=>item.employerMemberId==this.selectUserId);
                    this.onConfirmUser(true);
                }
                            
            }

        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 确认切换用户
     * bol 是否默认关闭弹窗
     * @private
     * @returns void
     */
    protected onConfirmUser(bol): void
    {
        this.$emit("getSelectUser", this.familyList[this.currentIndex],bol);
    }

    /**
     * 关闭弹窗
     * @private
     * @returns void
     */
     protected closePop(): void {
            this.$emit("close");
     }

    /**
     * 组件创建钩子
     * @protected
     * @returns void
     */
    protected created()
    {
        this.checkFamilyList();
    }

    /**
     * 修改用户
     * @private
     * @returns void
     */
    protected updateUser(index): void
    {
        this.currentIndex = index;
        this.$router.push({name: "family-operation",query: {userId: this.familyList[this.currentIndex].employerMemberId}});
    }

    
}
