
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";

/**
 * 自定义页面管理服务实例
 * @class
 * @version 1.0.0
 */
export default class PageService extends ServiceBase
{
    /*
     * 自定义页面管理服务实例
     * @private
     * @return PageService
     */
    private static _instance: PageService;

    /**
     * 自定义页面管理服务实例
     * @static
     * @property
     * @returns PageService
     */
    public static get instance(): PageService
    {
        if(!this._instance)
        {
            this._instance = new PageService();
        }
        
        return this._instance;
    }

    /**
     * 客户端获取企业页面配置
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getPageConfig(): Promise<IHttpResponse>
    {

        let response = await this.apis.getPageConfig({
        });

        return response;
    }

    /**
     * 客户端获取页面样式
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getPage(data: any): Promise<IHttpResponse>
    {
        let response = await this.apis.getPage({ data });

        return response;
    }


}
