
import { apis } from "apis";
import ServiceBase from "./service-base";
import IHttpResponse from "src/http/http-response";
import HttpClient from "src/http/http-client";
import { GaodeService, OrganizationService } from "src/services";

/**
 * 收货地址服务
 * @class
 * @version 1.0.0
 */
export default class AddressService extends ServiceBase
{

    /*
     * 地址服务实例
     * @private
     * @return AddressService
     */
    private static _instance: AddressService;

    /**
     * 地址服务实例
     * @static
     * @property
     * @returns AddressService
     */
    public static get instance(): AddressService
    {
        if(!this._instance)
        {
            this._instance = new AddressService();
        }
        
        return this._instance;
    }

    /**
     * 新增/修改收货地址
     * @public
     * @param {any} data 需要操作的数据
     * @returns {Promise<IHttpResponse>}
     */
    public async modifyAddress(data: any): Promise<IHttpResponse>
    {
        let response;

        if(data.id)
        {
            // 编辑
            response = await this.apis.updateAddress({
                data: data
            });
           
        }
        else
        {
            // 新增
            response = await this.apis.addAddress({
                data: data
            });
        }

        return response;
    }

    /**
     * 获取收货地址列表(分页）
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getAddressPageList(data: any): Promise<IHttpResponse>
    {

        let response = await this.apis.getAddressPageList({
            data: data
        });

        return response;
    }

    /**
     * 获取收货地址列表
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getAddressList(): Promise<IHttpResponse>
    {
        let response = await this.apis.getAddressList({});

        return response;
    }

    /**
     * 获取收货地址详情
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getAddressDetail(id: any): Promise<IHttpResponse>
    {

        let response = await this.apis.getAddressDetail({
            data:
            {
                id: id
            }
        });

        return response;
    }

    /**
     * 删除收货地址
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async deleteAddress(id: any): Promise<IHttpResponse>
    {

        let response = await this.apis.deleteAddress({
            params:
            {
                id: id
            }
        });

        return response;
    }

    /**
     * 设置默认地址
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async setAddressDefault(id: any): Promise<IHttpResponse>
    {

        let response = await this.apis.setAddressDefault({
            params:
            {
                id: id
            }
        });

        return response;
    }


}
